import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DownloadIcon from "@mui/icons-material/Download";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import InventoryIcon from "@mui/icons-material/Inventory";
import KeyIcon from "@mui/icons-material/Key";
import LaunchIcon from "@mui/icons-material/Launch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import MapIcon from "@mui/icons-material/Map";
import NotesIcon from "@mui/icons-material/Notes";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import StoreIcon from "@mui/icons-material/Store";
import TourIcon from "@mui/icons-material/Tour";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React from "react";
import { isMobile } from "react-device-detect";
import { ReactImageGalleryItem } from "react-image-gallery";
import styled from "styled-components";
import noImages from "../../assets/images/no-image.jpg";
import { ImageGalleryComponent } from "../../components/ImageGalleryComponent";
import StepperComponent from "../../components/Stepper";
import VerticalStepper from "../../components/Stepper/VerticalStepper";
import { FormDivider } from "../../components/styled/FormDivider";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import { getShippingLine } from "../../services/shipping-line-service";
import { IVehicle } from "../../services/vehicle-service";
import {
  VehicleStatus,
  copyText,
  decodeVin,
  downloadDocuments,
  viewStock,
} from "../../utils";
import { ClaimsDialog } from "./ClaimsDialog";

// const defaultTestImages = [
//   {
//     original:
//       "https://cargodocs.fra1.digitaloceanspaces.com/vehicles%2F6736726e53b99bb4b851c2aa%2Fimages%2Fresizer1.jpg",
//   },
//   {
//     original:
//       "https://cargodocs.fra1.digitaloceanspaces.com/vehicles%2F6736726e53b99bb4b851c2aa%2Fimages%2Fresizer.jpg",
//   },
//   {
//     original:
//       "https://cargodocs.fra1.digitaloceanspaces.com/vehicles%2F6736726e53b99bb4b851c2aa%2Fimages%2Fresizer3.jpg",
//   },
//   {
//     original:
//       "https://cargodocs.fra1.digitaloceanspaces.com/vehicles%2F6736726e53b99bb4b851c2aa%2Fimages%2Fresizer4.jpg",
//   },
// ];

const LinkIconContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const VehicleStatusIcons = {
  TRANSIT: <LocationOnIcon />,
  LOADED: <DirectionsBoatIcon />,
  UNLOADED: <WarehouseIcon />,
  DELIVERED: <SportsScoreIcon />,
  LOADED_ON_THE_TRUCK: <LocalShippingIcon />,
  AT_TERMINAL: <LocalShippingIcon />,
  TRANSHIPMENT: <DirectionsBoatIcon />,
};
interface IVehicleDetailsProps {
  images?: ReactImageGalleryItem[];
  vehicle?: IVehicle;
  share?: boolean;
}
export const VehicleDetailsForm: React.FC<IVehicleDetailsProps> = ({
  images,
  vehicle,
  share,
}) => {
  const { useState, useEffect } = React;
  const containerData = vehicle?.containerId || vehicle?.container;
  const user = getCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [claimsDialog, setClaimsDialog] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const getClickableLink = (link: string) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `https://${link}`;
  };
  const openAuction = (auctionUrl?: string) => {
    if (!auctionUrl) {
      return;
    }
    window.open(getClickableLink(auctionUrl), "_blank");
  };
  const trackContainer = async (container?: IContainer | undefined | null) => {
    if (!container) {
      return;
    }
    const { data } = await getShippingLine(container.shippingLineId);
    const { trackUrl } = data;
    if (trackUrl) {
      window.open(`${trackUrl}${container.name}`, "_blank");
    }
  };
  const shareVehicle = (vin: string) => {
    copyText(
      `https://autosrealm.com/share/${vin}`,
      "Vehicle URL copied successfully!"
    );
  };
  const downloadImages = () => {
    if (!images) return;

    const zip = new JSZip();
    const folder = zip.folder("images"); // folder name where all files will be placed in

    images?.forEach((image) => {
      const url = image.original;
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = url.substring(url.lastIndexOf("/") + 1);
      folder?.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, vehicle?.description));
  };
  const steps = vehicle?.statusHistory?.map((status, index) => {
    // console.log(statusFound, "STATUS FOUND");
    return {
      label: `${VehicleStatus[status.status]} ${
        status ? dayjs(status.date).format("YYYY-MM-DD") : ""
      } ${status?.note || ""}`,
      active: true,
      completed: true,
      // @ts-ignore
      icon: VehicleStatusIcons[status.status],
      // icon: VehicleStatusIcons[vehicleStatus.status](),
    };
  });
  const getKeysColor = (keys: any) => {
    if (keys === null) {
      return "warning";
    }
    return keys ? "primary" : "error";
  };
  const getKeysLabel = (keys: any) => {
    if (keys === null) {
      return "N/A";
    }
    return keys ? "Yes" : "No";
  };
  const renderLogo = () => {
    if (
      !isLoading &&
      share &&
      vehicle?.user?.allowBranding &&
      vehicle?.user?.logo
    )
      return <img src={vehicle?.user?.logo} width={200} alt="logo" />;
  };

  const handleClaimsDialog = () => {
    setClaimsDialog((prevStatus) => !prevStatus);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <FormDivider variant="fullWidth">Images</FormDivider>
          </Grid>

          <Grid item xs={12}>
            {images && images.length > 0 ? (
              <>
                {isLoading && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
                <div style={{ display: isLoading ? "none" : "block" }}>
                  <ImageGalleryComponent showThumbnails items={images || []} />
                </div>
              </>
            ) : (
              <img style={{ width: "100%" }} src={noImages} alt="no" />
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <div>
              <ImageGalleryComponent showThumbnails items={defaultTestImages} />
            </div>
          </Grid> */}
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <FormDivider variant="fullWidth">Main Information</FormDivider>
          </Grid>
          {/* LEFT PART */}
          <Grid item md={6}>
            <Box sx={{ display: "flex" }}>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() => decodeVin(vehicle?.vin || "")}
              >
                <Tooltip title="VIN (click for details)">
                  <ListItemIcon>
                    <CropFreeIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={vehicle?.vin} />
              </ListItemButton>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  vehicle?.vin &&
                  copyText(vehicle?.vin, "Vin copied successfully")
                }
              >
                <Tooltip title="Copy vin">
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            </Box>
            <ListItemButton onClick={() => {}}>
              <Tooltip title="Vehicle description">
                <ListItemIcon>
                  <DirectionsCarIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={vehicle?.description} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <KeyIcon color={getKeysColor(vehicle?.keys)} />
              </ListItemIcon>
              <ListItemText primary={getKeysLabel(vehicle?.keys)} />
            </ListItemButton>
            {((user && vehicle?.user?.phone) || share) && (
              <ListItemButton
                onClick={() =>
                  vehicle?.user?.phone &&
                  copyText(vehicle?.user?.phone, "Phone copied successfully")
                }
              >
                <Tooltip title="User">
                  <ListItemIcon>
                    <PersonIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={`Client : `}
                  secondary={
                    vehicle?.user
                      ? `${vehicle.user.firstName} - ${vehicle.user.phone}`
                      : ""
                  }
                />
              </ListItemButton>
            )}
            {vehicle?.warehouse && !share && (
              <>
                <ListItemButton
                  onClick={() =>
                    vehicle?.warehouse?.phone &&
                    copyText(
                      vehicle?.warehouse?.phone,
                      "Phone copied successfully"
                    )
                  }
                >
                  <Tooltip title="User">
                    <ListItemIcon>
                      <WarehouseIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary="Warehouse"
                    secondary={
                      vehicle?.warehouse
                        ? `${vehicle.warehouse.nickname} - ${vehicle.warehouse.phone}`
                        : ""
                    }
                  />
                </ListItemButton>
              </>
            )}
            {/* <FormDivider variant="fullWidth">Auction Information</FormDivider> */}
            <ListItemButton onClick={() => openAuction(vehicle?.auction?.url)}>
              <Tooltip title="Auction ">
                <ListItemIcon>
                  <StoreIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Auction"
                secondary={vehicle?.auction?.name}
              />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Auction Location ">
                <ListItemIcon>
                  <MapIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary="Auction Location"
                secondary={vehicle?.auctionLocation}
              />
            </ListItemButton>
            {((user && images && images.length > 0) || share) && (
              <ListItemButton onClick={() => downloadImages()}>
                <Tooltip title="User">
                  <ListItemIcon>
                    <DownloadIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Download all images" />
              </ListItemButton>
            )}
            {(user || share) && vehicle?.documents?.length > 0 && (
              <ListItemButton
                onClick={() =>
                  vehicle && vehicle?.paid && downloadDocuments(vehicle)
                }
              >
                <Tooltip title="User">
                  <ListItemIcon>
                    {vehicle?.paid ? (
                      <DownloadIcon color="primary" />
                    ) : (
                      <LockIcon color="error" />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Download documents" />
              </ListItemButton>
            )}
            {user && vehicle?.vin && (
              <ListItemButton onClick={() => shareVehicle(vehicle?.id)}>
                <Tooltip title="Share vehicle">
                  <ListItemIcon>
                    <ShareIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Client shareable link" />
              </ListItemButton>
            )}
            {user && !share && (
              <ListItemButton onClick={() => setClaimsDialog(true)}>
                <Tooltip title="Claims">
                  <ListItemIcon>
                    <GppMaybeIcon color="warning" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Claims" />
              </ListItemButton>
            )}
          </Grid>
          {/* RIGHT PART */}
          <Grid item md={6}>
            <Box sx={{ display: "flex" }}>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  viewStock(vehicle?.stockNumber || "", vehicle?.auction)
                }
              >
                <Tooltip title="Auction stock number">
                  <ListItemIcon>
                    <InventoryIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  secondary={vehicle?.stockNumber}
                  primary={
                    <LinkIconContainer>
                      <div>Stock number</div>
                      <LaunchIcon color="primary" fontSize="small" />
                    </LinkIconContainer>
                  }
                />
              </ListItemButton>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  vehicle?.stockNumber &&
                  copyText(
                    vehicle?.stockNumber,
                    "Stock number copied successfully"
                  )
                }
              >
                <Tooltip title="Copy stock number">
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            </Box>
            {/* <ListItemButton
              onClick={() => viewStock(vehicle?.stockNumber || "")}
            >
              <Tooltip title="Auction location">
                <ListItemIcon>
                  <LocationOnIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  vehicle?.auctionLocation ? vehicle?.auctionLocation : ""
                }
              />
            </ListItemButton> */}
            {containerData && (
              <Box sx={{ display: "flex" }}>
                <ListItemButton
                  onClick={() => {
                    trackContainer(containerData);
                  }}
                >
                  <Tooltip title="Container ">
                    <ListItemIcon>
                      <DirectionsBoatIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <LinkIconContainer>
                        {containerData?.name || vehicle?.containerName}{" "}
                        <LaunchIcon fontSize="small" color="primary" />
                      </LinkIconContainer>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  style={{
                    flexGrow: 0,
                  }}
                  onClick={() =>
                    (containerData?.name || vehicle.containerName) &&
                    copyText(
                      containerData?.name || vehicle.containerName,
                      "Container number copied successfully"
                    )
                  }
                >
                  <Tooltip title="Copy container">
                    <ListItemIcon>
                      <ContentCopyIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
                {/* <ListItemButton>
                  <Tooltip title="ETA ">
                    <ListItemIcon>
                      <CalendarMonthIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={dayjs(vehicle.container.expectedDate).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </ListItemButton> */}
              </Box>
            )}

            {vehicle?.destination && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title="Destination">
                  <ListItemIcon>
                    <TourIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={vehicle?.destination} />
              </ListItemButton>
            )}
            {vehicle?.finalDestination && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title="Final destination">
                  <ListItemIcon>
                    <SportsScoreIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary="Final destination"
                  secondary={
                    vehicle?.finalDestination ? vehicle?.finalDestination : ""
                  }
                />
              </ListItemButton>
            )}
            {vehicle?.expectedDate && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title="Expected date">
                  <ListItemIcon>
                    <CalendarMonthIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary="Expected Date"
                  secondary={
                    vehicle?.expectedDate
                      ? dayjs(vehicle.expectedDate).format("YYYY-MM-DD")
                      : " "
                  }
                />
              </ListItemButton>
            )}
            {vehicle?.titleSentDate && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title="Title sent date">
                  <ListItemIcon>
                    <ListAltIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary="Title sent date"
                  secondary={
                    vehicle?.titleSentDate
                      ? dayjs(vehicle.titleSentDate).format("YYYY-MM-DD")
                      : " "
                  }
                />
              </ListItemButton>
            )}
            {vehicle?.titleTrackingNumber && (
              <ListItemButton
                onClick={() =>
                  vehicle?.titleTrackingNumber &&
                  window.open(
                    `https://www.econt.com/services/track-shipment/${vehicle?.titleTrackingNumber}`,
                    "_blank"
                  )
                }
              >
                <Tooltip title="Title tracking number">
                  <ListItemIcon>
                    <ListAltIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary="Title tracking number"
                  secondary={vehicle?.titleTrackingNumber}
                />
              </ListItemButton>
            )}
            {!isMobile && (
              <Grid xs={12} md={6} sx={{ textAlign: "center" }} item>
                {renderLogo()}
              </Grid>
            )}
          </Grid>

          {vehicle?.notes && (
            <Grid item xs={12} md={12}>
              <ListItemButton onClick={() => {}}>
                <Tooltip title="Notes">
                  <ListItemIcon>
                    <NotesIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Notes" secondary={vehicle?.notes} />
              </ListItemButton>
            </Grid>
          )}
          {isMobile && (
            <Grid xs={12} md={6} sx={{ textAlign: "center" }} item>
              {renderLogo()}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormDivider variant="fullWidth">Status History</FormDivider>
        {!isMobile ? (
          <StepperComponent
            activeStep={vehicle?.statusHistory?.length}
            steps={steps}
          />
        ) : (
          <VerticalStepper
            activeStep={vehicle?.statusHistory?.length}
            steps={steps}
          />
        )}
      </Grid>

      {claimsDialog && (
        <ClaimsDialog
          vehicleData={vehicle}
          handleToggleOpen={handleClaimsDialog}
        />
      )}
    </>
  );
};
