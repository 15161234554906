import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/globalStyles";
import { Header } from "./components/Header";
import { LoginDialog } from "./components/LoginDialog";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Sidebar } from "./components/Sidebar";
import { darkTheme, lightTheme } from "./components/Themes";
import { WindowContextProvider } from "./context/WindowContextProvider";
import { Auctions } from "./pages/Auctions";
import { Containers } from "./pages/Containers";
import { ContainerDetails } from "./pages/Containers/ContainerDetails";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { Settings } from "./pages/Settings";
import { Shippers } from "./pages/Shippers";
import { ShippingLines } from "./pages/ShippingLines";
import { Titles } from "./pages/Titles";
import { Trucks } from "./pages/Trucks";
import { TruckDetails } from "./pages/Trucks/TruckDetails";
import { Users } from "./pages/users";
import { Vehicles } from "./pages/Vehicles";
import { Claims } from "./pages/Vehicles/Claims";
import { SearchVehicle } from "./pages/Vehicles/SearchVehicle";
import { VehicleDetails } from "./pages/Vehicles/VehicleDetails";
import { getCurrentUser } from "./services/auth-service";
import { UserRoles } from "./utils";
const Wrapper = styled.section`
  display: flex;
  min-height: 100vh;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const MainContainer = styled.section`
  flex-grow: 1;
  padding: 20px 16px 30px 16px;
  .cardHeader {
    font-family: Open Sans;
  }
`;
export const Themes = {
  DARK: "dark",
  LIGHT: "light",
};

export const App = () => {
  const user = getCurrentUser();
  const [userState, setUserState] = useState<any>(user);
  const [mode, setMode] = useState<PaletteMode>(
    localStorage.getItem("prefferDarkMode") === "1" ? "dark" : "light"
  );
  const themeToggler = () => {
    mode === Themes.LIGHT ? setMode("dark") : setMode("light");
  };
  window.addEventListener("logout", () => {
    setUserState(null);
    localStorage.removeItem("accessToken");
  });
  const theme = useMemo(
    () =>
      createTheme({
        // components: {
        //   MuiCssBaseline: {
        //     styleOverrides: `
        //       @font-face {
        //         font-family: 'Open Sans';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 400;
        //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        //       }
        //     `,
        //   },
        // },
        typography: {
          fontFamily: [
            "Open Sans",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
        palette: {
          mode,
        },
      }),
    [mode]
  );
  useEffect(() => {
    localStorage.setItem(
      "prefferDarkMode",
      (mode === Themes.DARK ? 1 : 0).toString()
    );
  }, [mode]);
  const url = window.location.href;
  console.log(url, "PARAMS");
  return (
    <ThemeProvider theme={mode === Themes.LIGHT ? lightTheme : darkTheme}>
      <Toaster />
      <MuiThemeProvider theme={theme}>
        {!userState && !url.includes("share") && <LoginDialog />}
        <CssBaseline />
        <>
          <GlobalStyles />
          <Wrapper>
            <WindowContextProvider>
              <Sidebar theme={mode} themeToggler={themeToggler} />
              <MainContainer>
                <Header />
                <Routes>
                  {/* Routes for all users */}
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/search-vehicle/:vin"
                    element={<SearchVehicle />}
                  />  
                  <Route path="/vehicles" element={<Vehicles />} />
                  
                  <Route path="/vehicles/:action" element={<Vehicles />} />
                  <Route
                    path="/vehicles/:action/:vehicleId"
                    element={<Vehicles />}
                  />

                  <Route path="/branding" element={<Profile />} />
                  <Route
                    path="/share/:vehicleId"
                    element={<VehicleDetails share />}
                  />
                  <Route
                    path="/share/container/:containerId"
                    element={<ContainerDetails />}
                  />

                  <Route
                    path="/search-truck/:truckId"
                    element={<TruckDetails />}
                  />
                  <Route
                    path="/search-container/:containerId"
                    element={<ContainerDetails />}
                  />
                  <Route path="/claims" element={<Claims />} />
                  <Route path="/vehicles/view/:vin" element={<Vehicles />} />
                  <Route path="/trucks" element={<Trucks />} />
                  <Route path="/containers" element={<Containers />} />
                  {/* Routes for admin users */}
                  <Route element={<ProtectedRoute isAllowed={user?.isAdmin} />}>
                    <Route path="/titles" element={<Titles />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                      path="/shipping-lines/:action"
                      element={<ShippingLines />}
                      />
                      <Route
                        path="/shipping-lines/:action/:shippingLineId"
                        element={<ShippingLines />}
                      />
                      <Route path="/users" element={<Users />} />
                      <Route path="/users/:action" element={<Users />} />
                      <Route path="/users/:action/:userId" element={<Users />} />
                      <Route path="/shipping-lines" element={<ShippingLines />} />
                      <Route path="/auctions" element={<Auctions />} />
                      <Route path="/auctions/:action" element={<Auctions />} />
                      <Route
                        path="/auctions/:action/:auctionId"
                        element={<Auctions />}
                      />
                      <Route path="/shippers" element={<Shippers />} />
                      <Route
                        path="/shippers/:action/:shipperId"
                        element={<Shippers />}
                      />
                      <Route path="/shippers/:action" element={<Shippers />} />
                  </Route>
                  {/* Еnd for admin users */}

                  {/* Routes not allowed for clients */}
                  <Route element={<ProtectedRoute isAllowed={user?.role !== UserRoles.CLIENT} />}>
                    <Route path="/trucks/:action" element={<Trucks />} />
                    <Route path="/trucks/:action/:truckId" element={<Trucks />} />
                    <Route path="/containers/:action" element={<Containers />} />
                    <Route
                      path="/containers/:action/:containerId"
                      element={<Containers />}
                    />
                    <Route
                      path="/containers/preview-vehicle/:vin"
                      element={<Containers />}
                    />
                  </Route>
                </Routes>
              </MainContainer>
            </WindowContextProvider>
          </Wrapper>
        </>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};
