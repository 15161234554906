import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { QuickStatusFilter } from "../../components/QuickStatusFilter";
import { getCurrentUser } from "../../services/auth-service";
import {
  deleteVehicle,
  getVehicles,
  IVehicle,
  updateVehicle,
} from "../../services/vehicle-service";
import {
  downloadDocuments,
  GridActions,
  UserRoles,
  VehicleStatus,
} from "../../utils";
import { CustomToolbar } from "../../utils/CustomToolbar";
import { VehicleDetails } from "./VehicleDetails";
import { VehicleDialog } from "./VehicleDialog";
import { VehicleStatusDialog } from "./VehicleStatusDialog";

export const Vehicles: FC = () => {
  const user = getCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeStatus = searchParams.get("status") as keyof typeof VehicleStatus;
  const page = searchParams.get("page") || "0";
  const params = useParams();
  const [pageState, setPageState] = useState(
    Number(page) > 0 ? Number(page) : 0
  );
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<string | undefined>(
    undefined
  );
  const [vehicleToPreview, setVehicleToPreview] = useState<string | undefined>(
    undefined
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const [vehicleToDelete, setVehicleToDelete] = useState<IVehicle | undefined>(
    undefined
  );
  useEffect(() => {
    if (params.vin) {
      setVehicleToPreview(params.vin);
    }
  }, [params.vin]);
  const actionMenu = (params: GridRenderCellParams) => {
    return (
      <ActionMenu>
        {user?.role !== UserRoles.CLIENT && (
          <IconButton onClick={() => handleToggleOpenEdit(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
        )}
        <IconButton onClick={() => setVehicleToPreview(params.row.id)}>
          <VisibilityIcon fontSize="medium" />
        </IconButton>
        {user?.role !== UserRoles.CLIENT && (
          <IconButton onClick={() => handleDeleteVehicle(params.row)}>
            <DeleteForeverIcon color="error" fontSize="medium" />
          </IconButton>
        )}
      </ActionMenu>
    );
  };
  const actionColumn: GridColDef = {
    field: "actions",
    sortable: false,
    // flex: 1,
    width: 130,
    headerAlign: "center",
    align: "center",
    headerName: "Actions",
    renderCell: (params: GridRenderCellParams) => {
      return actionMenu(params);
    },
  };
  const adminColumns: GridColDef[] = [
    {
      field: "user.nickname",
      headerName: "Nickname",
      width: 150,
      valueGetter: (params) => params.row.user?.nickname,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.user?.nickname || "";
      },
    },
    {
      field: "warehouse.nickname",
      headerName: "Warehouse",
      width: 150,
      valueGetter: (params) => params.row.warehouse?.nickname,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.warehouse?.nickname || "";
      },
    },
  ];
  const renderName = (data: GridCellParams) => {
    const vehicle: IVehicle = data.row;
    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);

  if(+monthAgo > + new Date(vehicle.updatedAt) && vehicle.status !== "DELIVERED") {
    return <Tooltip title="This vehicle has not been updated in the last month"><Button color="error">{data.value}</Button></Tooltip>;
  }
    return <Button>{data.value}</Button>;
  };
  const pageSize = 10;
  const mobileColumns: GridColDef[] = [
    ...(user?.isAdmin ? adminColumns : []),
    {
      field: "stockNumber",
      headerName: "Stock/Lot",
      width: 200,
    },

    { 
      field: "description",
      headerName: "Description",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            {params.row?.vin}<br/>
            <b>{params.row?.description}</b><br/>
            {params.row?.stockNumber}<br/>
          </div>
        );
      },
    },
    { 
      field: "vin",
      headerName: "VIN",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return renderName(params);
      },
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return VehicleStatus[params.value as keyof typeof VehicleStatus];
      },
    },
    {
      field: "statusHistory.date",
      headerName: "Creation date",
      // flex: 1,
      sortable: true,
      width: 150,
      valueGetter: (params) => params.row.statusHistory[0].date,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "documents",
      headerName: "Documents",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { documents, paid } = params.row;
        return paid ? (
          <Tooltip title="Download documents">
            <FolderIcon
              style={{ cursor: "pointer" }}
              onClick={() => downloadDocuments(params.row)}
              color={documents.length > 0 ? "primary" : "disabled"}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Vehicle not paid">
            <LockIcon color="error" />
          </Tooltip>
        );
      },
    },
    ...[actionColumn],
  ];
  const columns: GridColDef[] = [
    ...(user?.role !== UserRoles.CLIENT ? adminColumns : []),
    {
      field: "stockNumber",
      headerName: "Stock/Lot",
    },
    { field: "description", headerName: "Description", width: 220 },
    { 
      field: "vin",
      headerName: "VIN",
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        return renderName(params);
      },
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return VehicleStatus[params.value as keyof typeof VehicleStatus];
      },
    },
    {
      field: "statusHistory.date",
      headerName: "Creation date",
      flex: 1,
      width: 150,
      valueGetter: (params) => params.row.statusHistory[0].date,
      renderCell: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "expectedDate",
      headerName: "Expected date",
      flex: 1,
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "";
      },
    },
    {
      headerName: "Container name",
      field: "containerName",
    },
    {
      field: "documents",
      headerName: "Documents",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { documents, paid } = params.row;
        return paid ? (
          <Tooltip title="Download documents">
            <FolderIcon
              style={{ cursor: "pointer" }}
              onClick={() => downloadDocuments(params.row)}
              color={documents.length > 0 ? "primary" : "disabled"}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Vehicle not paid">
            <LockIcon color="error" />
          </Tooltip>
        );
      },
    },
    ...[actionColumn],
  ];
  const handleDeleteVehicle = (vehicle: IVehicle) => {
    setVehicleToDelete(vehicle);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (vehicleToDelete) await deleteVehicle(vehicleToDelete.id);
      toast.success("Vehicle deleted successfully");
    } catch (error) {
      console.log(error, "ERROR");
      toast.error("Something went wrong");
    }
    loadVehicles();
    setVehicleToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenEdit = (vehicle?: IVehicle) => {
    setOpen(true);
    setVehicleToEdit(vehicle?.id);
  };
  const handleToggleOpen = () => {
    setOpen(!open);
  };
  const handleToggleOpenDetails = () => {
    setVehicleToPreview(undefined);
  };
  const loadVehicles = async () => {
    setLoading(true);
    const { data: vehiclesData } = await getVehicles(
      // pageState,
      // pageSize,
      activeStatus || undefined
    );
    setVehicles(vehiclesData.data);
    //setTotalCount(vehiclesData.count);
    setVehicleToEdit(undefined);
    setOpen(false);
    setLoading(false);
    setOpenStatusUpdate(false);
  };
  const processRowUpdate = (newRow: IVehicle) => {
    toast.promise(updateVehicle(newRow), {
      loading: "Loading...",
      success: (res) => {
        return `Successfully updated vehicle status to : ${newRow?.status}`;
      },
      error: "Error when updating vehicle status",
    });
    return newRow;
  };
  const handleStatusClick = (status: any) => {
    if (status === activeStatus) {
      // 👇️ delete each query param
      searchParams.delete("status");

      // 👇️ update state after
      setSearchParams(searchParams);
      return;
    }
    searchParams.set("status", status);
    setSearchParams(searchParams);
  };
  const statuses = Object.keys(VehicleStatus).map((key) => {
    return {
      key,
      name: VehicleStatus[key as keyof typeof VehicleStatus],
    };
  });
  const onPageChange = (page: number, details: GridCallbackDetails<any>) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setPageState(page);
  };
  const handleOpenStatusUpdate = () => {
    setOpenStatusUpdate(true);
  };
  useEffect(() => {
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatus]);
  /* vehicle details dialog */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Vehicles</Typography>
        <div
          style={{
            display: "flex",
            gap: 5,
          }}
        >
          <Button variant="contained" onClick={handleToggleOpen}>
            Add Vehicle
          </Button>
          {user?.isAdmin && (
            <Button
              disabled={selection?.length === 0}
              variant="contained"
              onClick={handleOpenStatusUpdate}
            >
              Update Status
            </Button>
          )}
        </div>
      </GridActions>
      <GridActions>
        <QuickStatusFilter
          activeStatus={activeStatus}
          onClick={handleStatusClick}
          statuses={statuses}
        />
        <div>
          <ConfirmDialog
            handleConfirm={handleConfirmDelete}
            handleClose={() => setDeleteOpen(false)}
            open={deleteOpen}
            title="Are you sure to delete this vehicle?"
          >
            Deleting vehicle <strong>{vehicleToDelete?.description}</strong>
          </ConfirmDialog>
          {openStatusUpdate && (
            <VehicleStatusDialog
              vehicles={vehicles.filter((vehicle: IVehicle) => {
                return selection.includes(vehicle.id);
              })}
              submitCallback={loadVehicles}
              handleToggleOpen={() => {
                setOpenStatusUpdate(!openStatusUpdate);
              }}
            />
          )}
          {/* vehicle edit/create form */}
          {open && (
            <VehicleDialog
              vehicleId={vehicleToEdit}
              submitCallback={loadVehicles}
              handleToggleOpen={handleToggleOpen}
            />
          )}
          {vehicleToPreview && (
            <VehicleDetails
              vehicleId={vehicleToPreview}
              handleToggleOpen={() => handleToggleOpenDetails()}
            />
          )}
        </div>
      </GridActions>
      <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
        <DataGrid
         getRowHeight={() => isMobile ? 'auto' : undefined}
          initialState={{
            sorting: {
              sortModel: [{ field: "statusHistory.date", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns containerName, the other columns will remain visible
                containerName: false,
                createdAt: !isMobile,
                vin: !isMobile,
                stockNumber: !isMobile
              },
            },
          }}
          // onCellClick={(params: GridCellParams) => {
          //   if (
          //     params.field === "actions" ||
          //     params.field === "__check__" ||
          //     params.field === "documents"
          //   ) {
          //     // do not open edit
          //     return;
          //   }
          //   setVehicleToPreview(params.row.id);
          // }}
          onPageChange={onPageChange}
          // rowCount={totalCount}
          loading={loading}
          selectionModel={selection}
          pagination
          //paginationMode="server"
          // processRowUpdate={processRowUpdate}
          onSelectionModelChange={(itm) => setSelection(itm)}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={vehicles}
          page={pageState}
          columns={isMobile ? mobileColumns : columns}
          pageSize={pageSize}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxSelection
        />
      </div>
    </Box>
  );
};
