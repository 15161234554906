
import { Button, ImageList } from "@mui/material";
import update from "immutability-helper";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactImageGalleryItem } from "react-image-gallery";
import { DraggableImage } from "../../pages/Vehicles/DraggableImage";
interface ImageUploadProps {
    buttonLabel: string;
    onImagesChange: (imagesToUpload: any) => void;
}
export const ImageUpload: React.FC<ImageUploadProps> = ({
    buttonLabel,
    onImagesChange
}) => {
  const [imagesToAdd, setImagesToAdd] = React.useState<any>([]);
    const imageObject = (file: any) => {
        return {
          name: file.name ? file.name : file,
          original: typeof file === "object" ? URL.createObjectURL(file) : file,
          thumbnail: typeof file === "object" ? URL.createObjectURL(file) : file,
        };
      };
    const MAX_FILE_SIZE = 5096; // 5MB
  const [imagesState, setImages] = React.useState<any[]>([]);
  const handleMultipleImages = React.useCallback(
    (targetFiles: any) => {
      const selectedFiles: ReactImageGalleryItem[] = [];
      const images: any = [];
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        images.push(file);
        return selectedFiles.push(imageObject(file));
      });
      setImages(imagesState.concat(selectedFiles));
      setImagesToAdd(imagesToAdd.concat(images));
    },
    [imagesState, imagesToAdd]
  );

  const moveImage = React.useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
    ) => {
      setImages((prevImages) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
    },
    []
  );

  const handleRemoveImage = (
    imageToDelete: any,
  ) => {
    setImages((current) => current.filter((image) => imageToDelete !== image));
    setImagesToAdd((current: any) => current.filter((image: any) => imageToDelete.name !== image.name));
  };
  React.useEffect(() => {
    onImagesChange(imagesToAdd);
  }, [imagesToAdd])
  return (
    <>
            <Button variant="contained" component="label">
                {buttonLabel}
                <input
                  hidden
                  id="images"
                  name="images"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(event) => {
                    const files = event.target.files;
                        handleMultipleImages(files);
                  }}
                  multiple
                />
              </Button>

              <ImageList variant="quilted" cols={4} rowHeight={120}>
                <DndProvider backend={HTML5Backend}>
                  {imagesState?.map((item, index) => (
                    <DraggableImage
                      id={index}
                      moveCard={(dragIndex: number, hoverIndex: number) => 
                        moveImage(dragIndex, hoverIndex)
                      }
                      removeImage={(imageToDelete) =>
                        handleRemoveImage(imageToDelete)
                      }
                      image={item}
                      index={index}
                    />
                  ))}
                </DndProvider>
              </ImageList>
    </>
  );
};
