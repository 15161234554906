import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import NotesIcon from '@mui/icons-material/Notes';
import PersonIcon from "@mui/icons-material/Person";
import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import noImages from "../../assets/images/no-image.jpg";
import { CommonDialog } from "../../components/CommonDialog";
import { ImageGalleryComponent } from "../../components/ImageGalleryComponent";
import { FormDivider } from "../../components/styled/FormDivider";
import { getUser, IUser } from "../../services/user-service";
import { ClaimStatus, IClaim } from "../../services/vehicle-service";
import { copyText, mapImagesToGalleryItems } from "../../utils";
interface ClaimPreviewProps {
  claimData?: IClaim;
  handleToggleOpen?: () => void;
  open?: boolean;
  share?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export const ClaimPreview: React.FC<ClaimPreviewProps> = ({
  open = false,
  claimData,
  share,
  handleToggleOpen,
}) => {
  const [value, setValue] = React.useState(0);
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  const [userData, setUserData] = React.useState<IUser | null>(null);
  const [adminImages, setAdminImages] = React.useState<ReactImageGalleryItem[]>([]);
  useEffect(() => {
    if (claimData) {
      const mappedImages = mapImagesToGalleryItems(claimData.images || []);
      setImages(mappedImages);
    }
  }, [claimData?.images, claimData]);


  const loadUserData = React.useCallback(async () => {
    const userId = claimData?.vehicleId?.userId;
    if (userId) {
      const { data } = await getUser(userId);
      setUserData(data);
    }
  }, [claimData]);
  useEffect(() => {
    if (claimData) {
      loadUserData()
      const mappedImages = mapImagesToGalleryItems(claimData.adminImages || []);
      setAdminImages(mappedImages);
    }
  }, [claimData?.adminImages, claimData, loadUserData]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
  };
  const handleClose = () => {
    return false;
  };


  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <CommonDialog
      fullWidth
      maxWidth="md"
      title={`Claim Details ${claimData?.description} ${
        claimData?.status
          ? ` - ${
              ClaimStatus[claimData.status as keyof typeof ClaimStatus]
            }`
          : ""
      }`}
      handleToggleOpen={toggleOpenDialog}
      open
      onClose={handleClose}
    >
    <>
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Claim photos" {...a11yProps(0)} />
              <Tab label="Admin photos" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {imagesState && imagesState.length > 0 ? (
              <ImageGalleryComponent showThumbnails items={imagesState || []} />
            ) : (
              <img style={{ width: "100%" }} src={noImages} alt="no" />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {imagesState && imagesState.length > 0 ? (
              <ImageGalleryComponent showThumbnails items={adminImages || []} />
            ) : (
              <img style={{ width: "100%" }} src={noImages} alt="no" />
            )}
          </CustomTabPanel>
          </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Vehicle Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  claimData?.vehicleId?.vin &&
                  copyText(claimData?.vehicleId?.vin, "Vin copied successfully")
                }
              >
                <Tooltip title="Copy vin">
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={claimData?.vehicleId.vin} />
              </ListItemButton>
            <ListItemButton>
              <Tooltip title="Vehicle description">
                <ListItemIcon>
                  <DirectionsCarIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={claimData?.vehicleId.description} />
            </ListItemButton>

          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Claim Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton>
              <Tooltip title="Description">
                <ListItemIcon>
                  <NotesIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={claimData?.description} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Notes">
                <ListItemIcon>
                  <DirectionsCarIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={claimData?.notes} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Created date">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dayjs(claimData?.createdAt).format("YYYY-MM-DD")} />
            </ListItemButton>
            {userData?.phone && (
              <ListItemButton
                onClick={() =>
                  userData?.phone &&
                  copyText(userData?.phone, "Phone copied successfully")
                }
              >
                <Tooltip title="User">
                  <ListItemIcon>
                    <PersonIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary="Client"
                  secondary={
                    userData
                      ? `${userData.firstName} - ${userData.phone}`
                      : ""
                  }
                />
              </ListItemButton>
            )}
          </List>
        </Grid>
      </Grid>
    </>
    </CommonDialog>
  );
};
